<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
<!--        <left-bar-comp>-->
<!--            <sports-left-bar-comp></sports-left-bar-comp>-->
<!--        </left-bar-comp>-->

        <div class="content_section">
            <div class="content">
                <sub-title>공지사항</sub-title>
                <div class="main_panel">
                    <div class="notice">
                        <div class="notice_list" v-for="(n,index) in noticeList" :key="index">
                            <div class="n">
                                {{index+1}}
                            </div>
<!--                            <div class="t" :style="{'color':n.titleColor}" @click="showContent(n.id)">-->
                            <router-link tag="div" :to="{path:'/board_detail/' + n.id}" class="t">
                                <span :style="{'color':n.titleColor}">{{n.title}}</span>
                            </router-link>
<!--                            <div class="c" v-if="clickNumber == n.id" v-html="n.content">-->
<!--                            </div>-->
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- right 메뉴 -->
        <!--<right-bar-comp>
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>-->
        <foot-comp></foot-comp>

    </div>
</template>

<script>
    import {getNoticeList} from "../../network/userRequest";
    import TopbarComp from "../../components/TopbarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import sportsConst from "../../common/sportsConst";
    import RightBarComp from "../../components/RightBarComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import FootComp from "../../components/FootComp";
    import SubTitle from "../../components/SubTitle";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";

    export default {
        name: "Notice",
        mixins:[postionMixin],
        components: {
            RightButtonsComp,
            UserInfoComp,
            SubTitle,
            FootComp,
            RightBarBannerComp, SportsBetCartComp, RightBarComp, SportsLeftBarComp, LeftBarComp, TopbarComp
        },
        data() {
            return {
                sportsConst,
                noticeList: [],
                clickNumber: 0,
                position:"공지사항",
            }
        },
        methods: {
            showContent(id){
                if(id === this.clickNumber){
                    this.clickNumber = 0
                }else {
                    this.clickNumber = id;
                }

            }
        },
        created() {
            this.$store.commit(RECEIVE_SHOW_LOADING)
            this.clickNumber = this.$route.query.id
            /*공지사항추출*/
            getNoticeList(100).then(res => {
                if (res.data.success) {
                    this.noticeList = res.data.data
                }
                this.$store.commit(RECEIVE_HIDE_LOADING)
            })
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");
    @import url("../../assets/css/notice.css");
</style>